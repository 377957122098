* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.app {
  display: flex;
  align-items: center;
  justify-content: center;
}

.parent {
  width: 450px;
  margin: auto;
  padding: 0 2rem 1rem 2rem;
  background: #ffffff;
  border-radius: 25px;
}

.file-upload {
  text-align: center;
  border: 3px dashed rgb(210, 227, 244);
  padding: 1.5rem;
  position: relative;
  cursor: pointer;
  border-radius: 2rem;
}

.file-upload p {
  font-size: 0.87rem;
  margin-top: 10px;
  color: #bbcada;
}

.file-upload input {
  display: block;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  cursor: pointer;
}