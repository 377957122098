.header-class {
    background-color: rgb(251, 251, 251);
    text-transform: none;
    font-weight: 700;
    font-size: 0.75rem;
    letter-spacing: 0.05em;
    color: #7F7D80;
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.body-class {
    font-size: 0.8125rem;
    font-weight: 500;
    letter-spacing: 0.03em;
    padding-left: 0rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}